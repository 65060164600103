<template>
  <div>
    <h2>基本設定</h2>
    <div class="">
      テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
    </div>
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body mt-1 mb-2">
        テキストテキストテキストテキストテキストテキストテキストテキスト
      </div>
    </b-alert>
    <b-card
      title=""
    >
      <div
        v-for="(item,index) in menulist"
        :key="index"
        :class="index ? 'mt-2':''"
      >
        <b-row>
          <b-col lg="4">
            <h5
              class="text-capitalize mb-75"
            >
              {{ item.label }}
            </h5>
          </b-col>
          <b-col>
            <b-card-text
              v-if="item.type === 'selectSingle'"
            >
              <div
                v-if="profileDic[item.key]"
              >
                {{ profileDic[item.key]['labelName'] }}
              </div>
            </b-card-text>
            <b-card-text
              v-else
            >
              <div
                v-if="profileDic[item.key]"
              >
                {{ profileDic[item.key] }}
              </div>
              <div
                v-else
              >
                登録なし
              </div>
            </b-card-text>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col offset-md="8">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="edit"
          >
            編集する
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BButton, BCard, BCardText, BAlert,
} from 'bootstrap-vue'
import menulist from '@/components/conf/student/学習PF_受講生_基本設定_20210921.json'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BCardText,
    BAlert,
  },
  directives: {
    Ripple,
  },
  props: {
    profileDic: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      menulist,
    }
  },
  methods: {
    edit() {
      this.$router.push({ path: 'student-basicsetting-edit' })
    },
  },
}
</script>
